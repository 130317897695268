import React, { useState } from "react";
import "@styles/CreatePlaceModal.css"; // Asegúrate de crear este archivo para los estilos
import { createPlace } from "@services/placeService";

const CreatePlaceModal = ({ isOpen, onClose }) => {
  const [newPlace, setNewPlace] = useState({
    name: "",
    address: "",
    nit: "",
  });

  const [errorMessage, setErrorMessage] = useState(""); // Estado para manejar errores
  const [isLoading, setIsLoading] = useState(false); // Estado para el loader
  const [successMessage, setSuccessMessage] = useState(""); // Estado para mensaje satisfactorio

  if (!isOpen) return null; // No renderizar si el modal no está abierto

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPlace((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Validación básica
    if (!newPlace.name || !newPlace.address || !newPlace.nit) {
      setErrorMessage("Todos los campos con (*) son obligatorios.");
      return;
    }

    setIsLoading(true); // Mostrar loader antes de guardar
    setErrorMessage("");

    try {
      await createPlace(newPlace);

      setSuccessMessage("Lugar registrado exitosamente.");
      setNewPlace({
        name: "",
        address: "",
        nit: "",
      });

      setTimeout(() => {
        setSuccessMessage("");
        onClose(); // Cerrar modal después de un tiempo
      }, 2000);
    } catch (error) {
      setErrorMessage("Error al registrar el lugar. Intente de nuevo.");
      console.error("Error al crear el lugar:", error);
    } finally {
      setIsLoading(false); // Ocultar loader
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Registrar Nueva sede</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="loader"></div> // Loader mientras se guarda
          ) : (
            <div className="form-section">
              <div className="form-grid">
                <div className="input-group">
                  <label htmlFor="name">
                    Nombre del Lugar<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={newPlace.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="address">
                    Dirección<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={newPlace.address}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="nit">
                    NIT<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="nit"
                    name="nit"
                    value={newPlace.nit}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {!isLoading && (
            <button className="save-button" onClick={handleSave}>
              Guardar
            </button>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreatePlaceModal;
