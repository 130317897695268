import React, { useState } from "react";
import "@styles/CreateOwnerModal.css"; // Estilos específicos para esta modal
import { createOwner } from "@services/ownerService"; // Supuesta llamada al servicio de creación de propietario

const CreateOwnerModal = ({ isOpen, onClose }) => {
  const [newOwner, setNewOwner] = useState({
    first_name: "",
    second_name: "",
    first_lastname: "",
    second_lastname: "",
    cedula: "",
  });

  const [errorMessage, setErrorMessage] = useState(""); // Estado para manejar errores
  const [isLoading, setIsLoading] = useState(false); // Estado para el loader
  const [successMessage, setSuccessMessage] = useState(""); // Estado para mensaje satisfactorio

  if (!isOpen) return null; // No renderizar si el modal no está abierto

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewOwner((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Validación básica
    if (!newOwner.first_name || !newOwner.first_lastname || !newOwner.cedula) {
      setErrorMessage("Todos los campos con (*) son obligatorios.");
      return;
    }

    setIsLoading(true); // Mostrar loader antes de guardar
    setErrorMessage("");

    try {
      await createOwner(newOwner); // Llamada al servicio de creación de propietario

      setSuccessMessage("Propietario registrado exitosamente.");
      setNewOwner({
        first_name: "",
        second_name: "",
        first_lastname: "",
        second_lastname: "",
        cedula: "",
      });

      setTimeout(() => {
        setSuccessMessage("");
        onClose(); // Cerrar modal después de un tiempo
      }, 2000);
    } catch (error) {
      setErrorMessage("Error al registrar el propietario. Intente de nuevo.");
      console.error("Error al crear el propietario:", error);
    } finally {
      setIsLoading(false); // Ocultar loader
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Registrar Nuevo Propietario</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="loader"></div> // Loader mientras se guarda
          ) : (
            <div className="form-section">
              <div className="form-grid">
                <div className="input-group">
                  <label htmlFor="first_name">
                    Primer Nombre<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={newOwner.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="second_name">Segundo Nombre</label>
                  <input
                    type="text"
                    id="second_name"
                    name="second_name"
                    value={newOwner.second_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="first_lastname">
                    Primer Apellido<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="first_lastname"
                    name="first_lastname"
                    value={newOwner.first_lastname}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="second_lastname">Segundo Apellido</label>
                  <input
                    type="text"
                    id="second_lastname"
                    name="second_lastname"
                    value={newOwner.second_lastname}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="cedula">
                    Cédula<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="cedula"
                    name="cedula"
                    value={newOwner.cedula}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {!isLoading && (
            <button className="save-button" onClick={handleSave}>
              Guardar
            </button>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreateOwnerModal;
