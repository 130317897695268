import React, { useEffect, useState } from "react";
import "@styles/CreateVehicleModal.css"; // Importa los estilos específicos para esta modal
import { createCar } from "@services/carService"; // Servicio para crear un vehículo
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import { getOwners } from "@services/ownerService";

const CreateVehicleModal = ({ isOpen, onClose }) => {
  const [newVehicle, setNewVehicle] = useState({
    license_plate: "",
    model: "",
    brand: "",
    owner_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const ownersData = await getOwners();
        const options = ownersData.data.map((owner) => ({
          value: owner.id,
          label: `${owner.cedula} - ${owner.first_name} ${owner.second_name} ${owner.first_lastname} ${owner.second_lastname}`,
        }));
        setOwners(options);
        
      } catch (error) {
        console.error("Error fetching owners:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOwners();
  }, []);

  if (!isOpen) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!newVehicle.license_plate || !newVehicle.model || !newVehicle.brand) {
      setErrorMessage("Todos los campos son obligatorios.");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      await createCar(newVehicle);
      setTimeout(() => {
        setIsLoading(false);
        setSuccessMessage("Vehículo registrado exitosamente.");
        setNewVehicle({
          license_plate: "",
          model: "",
          brand: "",
        });

        setTimeout(() => {
          setSuccessMessage("");
          onClose();
        }, 2000);
      }, 2000);
    } catch (error) {
      console.error("Error creando el vehículo:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Registrar Nuevo Vehículo</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <div className="form-section">
              <div className="form-grid">
                <Col md={8} className="pb-3">
                  <Form.Text>Propietario</Form.Text>
                  <Select
                    isSearchable={true}
                    placeholder="Propietario"
                    options={owners}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    value={owners.find((option) => option.value === newVehicle.owner_id)}
                    onChange={(option) =>
                      setNewVehicle((prev) => ({ ...prev, owner_id: option.value }))
                    }
                  />
                </Col>
                <div className="input-group">
                  <label htmlFor="license_plate">
                    Placa<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="license_plate"
                    name="license_plate"
                    value={newVehicle.license_plate}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="model">
                    Modelo<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="model"
                    name="model"
                    value={newVehicle.model}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="brand">
                    Marca<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="brand"
                    name="brand"
                    value={newVehicle.brand}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {!isLoading && (
            <button className="save-button" onClick={handleSave}>
              Guardar
            </button>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreateVehicleModal;

