import React, { useState } from "react";
import "@styles/CreateEmployeeModal.css";
import EventRegistration from "@components/EventCamera/EventRegistration";
import { createEmployee } from "@services/employeeService";

const CreateEmployeeModal = ({ isOpen, onClose }) => {
  const [newEmployee, setNewEmployee] = useState({
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    document: "",
    photo: "",
    phone: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Estado para el loader
  const [successMessage, setSuccessMessage] = useState(""); // Estado para el mensaje de éxito

  if (!isOpen) {
    return null; // No renderizar nada si el modal no está abierto
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhotoCapture = (photo) => {
    setNewEmployee({...newEmployee, ...photo});
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Validación básica
    if (
      !newEmployee.first_name ||
      !newEmployee.last_name ||
      !newEmployee.document ||
      !newEmployee.phone ||
      !newEmployee.photo
    ) {
      setErrorMessage("Todos los campos con (*) son obligatorios.");
      return;
    }

    setIsLoading(true); // Mostrar loader antes de guardar
    setErrorMessage("");

    const response = await createEmployee(newEmployee);

    setTimeout(() => {
      setIsLoading(false); // Ocultar loader después de guardar
      setSuccessMessage("Empleado registrado exitosamente."); // Mostrar mensaje de éxito
      setNewEmployee({
        first_name: "",
        second_name: "",
        last_name: "",
        second_last_name: "",
        document: "",
        photo: "",
        phone: "",
      });

      setTimeout(() => {
        setSuccessMessage("");
        onClose();
      }, 2000);
    }, 2000);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Registrar Nuevo Empleado</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="loader"></div> // Loader mientras se guarda
          ) : (
            <div className="form-section">
              <div className="form-grid">
                <div className="input-group">
                  <label htmlFor="first_name">
                    Primer Nombres<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={newEmployee.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="second_name">Segundo Nombre</label>
                  <input
                    type="text"
                    id="second_name"
                    name="second_name"
                    value={newEmployee.second_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="last_name">
                    Primer Apellido<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={newEmployee.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="second_last_name">Segundo Apellido</label>
                  <input
                    type="text"
                    id="second_last_name"
                    name="second_last_name"
                    value={newEmployee.second_last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="document">
                    Documento de Identificación
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="document"
                    name="document"
                    value={newEmployee.document}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="phone">
                    Celular<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={newEmployee.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="photo">Seleccionar foto del empleado</label>
                  <input
                    type="file"
                    id="photo"
                    name="photo"
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="photo">
                    Tomar foto<span style={{ color: "red" }}>*</span>
                  </label>
                  <EventRegistration data={newEmployee} onPhotoTaken={handlePhotoCapture} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {!isLoading && (
            <button className="save-button" onClick={handleSave}>
              Guardar
            </button>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreateEmployeeModal;
