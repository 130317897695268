import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AuthContext } from "@context/AuthContext";
import { ModalProvider, useModal } from "@context/ModalContext";
import "@styles/App.css";
import CreateEmployeeModal from "@components/Employee/CreateEmployeeModal"
import RegisterEventEmployee from "@components/RegisterEventEmployee/RegisterEventEmployee"
import RegistryVehicleModal from "@components/Vehicles/RegistryVehicleModal"
import CreateOwnerModal from "@components/Owner/CreateOwnerModal"
import CreateVehicleModal from "@components/Vehicles/CreateVehicleModal"
import CreatePlaceModal from "@components/Place/CreatePlaceModal"

// Lazy loading de las rutas principales
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Employees = lazy(() => import("@pages/Employees"));
const Login = lazy(() => import("@pages/Login"));
const Places = lazy(() => import("@pages/Places"));
const Vehicles = lazy(() => import("@pages/Vehicles"));
const EmployeeEvent = lazy(() => import("@pages/EmployeeEvent"));
const PrivateRoute = lazy(() => import("@components/PrivateRoute"));

const Modals = () => {
  const { modals, closeModal } = useModal();

  return Object.entries(modals).map(([modalName, isOpen]) => {
    const ModalComponent = {
      createEmployeeModal: CreateEmployeeModal,
      registerEventEmployee: RegisterEventEmployee,
      registryVehicleModal: RegistryVehicleModal,
      createOwnerModal: CreateOwnerModal,
      createVehicleModal: CreateVehicleModal,
      createPlaceModal: CreatePlaceModal,
    }[modalName];
    return (
      isOpen && (
        <ModalComponent
          key={modalName}
          isOpen={isOpen}
          onClose={() => closeModal(modalName)}
        />
      )
    );
  });
};

const App = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.toggle("login-bg", location.pathname === "/");
  }, [location]);

  return (
    <ModalProvider>
      <Modals />
      <Suspense fallback={<div>Redireccionando...</div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/empleados" element={<Employees />} />
            <Route path="/sedes" element={<Places />} />
            <Route path="/vehiculos" element={<Vehicles />} />
            <Route path="/eventoEmpleado" element={<EmployeeEvent />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </ModalProvider>
  );
};

export default App;
