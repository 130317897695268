import React, { useState } from "react";
import EventRegistration from "../EventCamera/EventRegistration";
import "@/styles/RegisterEventEmployee.css"; // Estilos exclusivos de esta modal

const RegisterEventEmployee = ({ isOpen, onClose, onSave }) => {
  const [employeeData, setEmployeeData] = useState({
    cc: "",
    photo_employee: "",
    event_type: "in", // Valor predeterminado de evento
    date_time: new Date(),
    created_at: new Date(),
    update_at: new Date(),
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhotoCapture = (photo) => {
    setEmployeeData((prev) => ({
      ...prev,
      photo_employee: photo,
    }));
  };

  const handleSave = () => {
    if (!employeeData.cc || !employeeData.photo_employee) {
      setErrorMessage("Debes ingresar el número de cédula y tomar la foto.");
      return;
    }

    onSave(employeeData); // Guardar los datos
    setEmployeeData({
      cc: "",
      photo_employee: "",
      event_type: "in",
      date_time: new Date(),
      created_at: new Date(),
      update_at: new Date(),
    });
    onClose(); // Cerrar la modal
  };

  if (!isOpen) return null; // No mostrar la modal si no está abierta

  return (
    <div className="register-event-modal-overlay">
      <div className="register-event-modal-container">
        <div className="register-event-modal-header">
          <h2>Registrar Evento del Empleado</h2>
          <button className="register-event-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="register-event-modal-body">
          <div className="register-event-form-section">
            <div className="register-event-input-group">
              <label htmlFor="cc">Cédula de Ciudadanía</label>
              <input
                type="text"
                id="cc"
                name="cc"
                value={employeeData.cc}
                onChange={handleChange}
              />
            </div>

            <div className="register-event-input-group">
              <label htmlFor="event_type">Tipo de Evento</label>
              <select
                id="event_type"
                name="event_type"
                value={employeeData.event_type}
                onChange={handleChange}
              >
                <option value="in">Entrada</option>
                <option value="out">Salida</option>
              </select>
            </div>

            {/* Componente EventRegistration para tomar la foto */}
            <div className="register-event-input-group">
              <EventRegistration onPhotoTaken={handlePhotoCapture} />
            </div>

            {errorMessage && (
              <p className="register-event-error-message">{errorMessage}</p>
            )}
          </div>
        </div>
        <div className="register-event-modal-footer">
          <button className="register-event-save-button" onClick={handleSave}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterEventEmployee;
