import axios from "axios";

const env = import.meta.env.VITE_API_ENV || "DEV";
const apiUrl =
  env === "DEV"
    ? import.meta.env.VITE_API_URL_DEV
    : import.meta.env.VITE_API_URL_PROD;
const token = localStorage.getItem("token");

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default api;
