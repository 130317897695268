import React, { useEffect, useState } from "react";
import { getCar } from "@mocks/getCar";
import "@styles/RegistryVehicleModal.css"; // Estilos personalizados para la modal de vehículos
import { getcars } from "@services/carService";
import { createCarRegistry } from "@services/carRegistryService";

const RegistryVehicleModal = ({ isOpen, onClose, onSave }) => {
  const [vehicleData, setVehicleData] = useState({
    car_id: "",
    even_type: "Entrada",
    datetime: new Date(),
  });
  const [vehicles, setVehicles] = useState([]); // Lista de vehículos

  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicleData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const loadVehicles = async () => {
    try {
      const response = await getcars();
      setVehicles(response.data);
    } catch (error) {
      console.error("Error loading vehicles", error);
    }
  };

  useEffect(() => {
    loadVehicles();
  }, []);

  console.log(vehicleData)

  const handleSave = async() => {
    console.log(vehicleData);
    if (!vehicleData.car_id) {
      setErrorMessage("Debes seleccionar el vehículo.");
      return;
    }

    await createCarRegistry(vehicleData);
    setVehicleData({
      car_id: "",
      event_type: "in",
      date_time: new Date(),
      create_at: new Date(),
      update_at: new Date(),
    });
    onClose(); // Cerrar la modal
  };

  if (!isOpen) return null; // No mostrar la modal si no está abierta
  return (
    <div className="registry-vehicle-modal-overlay">
      <div className="registry-vehicle-modal-container">
        <div className="registry-vehicle-modal-header">
          <h2>Registrar Evento del Vehículo</h2>
          <button className="registry-vehicle-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="registry-vehicle-modal-body">
          <div className="registry-vehicle-form-section">
            <div className="registry-vehicle-input-group">
              <label htmlFor="car_id">Vehículo</label>
              <select
                id="car_id"
                name="car_id"
                value={vehicleData.car_id}
                onChange={handleChange}
              >
                <option value="">Selecciona un vehículo</option>
                {vehicles.map((car) => (
                  <option key={car.id} value={car.id}>
                    {car.license_plate} - {car.brand} ({car.model})
                  </option>
                ))}
              </select>
            </div>

            <div className="registry-vehicle-input-group">
              <label htmlFor="even_type">Tipo de Evento</label>
              <select
                id="even_type"
                name="even_type"
                value={vehicleData.even_type}
                onChange={handleChange}
              >
                <option value="Entrada">Entrada</option>
                <option value="Salida">Salida</option>
              </select>
            </div>

            {errorMessage && (
              <p className="registry-vehicle-error-message">{errorMessage}</p>
            )}
          </div>
        </div>
        <div className="registry-vehicle-modal-footer">
          <button className="registry-vehicle-save-button" onClick={handleSave}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistryVehicleModal;
