import React, { useEffect, useState } from "react";
import { captureImageToBase64 } from "@utils/imageUtils";
import "@styles/EventRegistration.css";    

const EventRegistration = ({ data, onPhotoTaken }) => {
  const items = data;
  const [videoStream, setVideoStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [documentNumber, setDocumentNumber] = useState("");
  const [disabledCapture, setDisabledCapture] = useState(true);
  
  useEffect(() => {
    if (capturedImage) {
      const copyState = { ...items };
      copyState["photo"] = capturedImage;
      onPhotoTaken(copyState);
    }
  }, [capturedImage]);

  // Iniciar la cámara
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setVideoStream(stream);
      const videoElement = document.getElementById("video");
      videoElement.srcObject = stream;
    } catch (error) {
      console.error("Error al acceder a la cámara:", error);
    }
  };

  useEffect(() => {
    if (videoStream) {
      setDisabledCapture(false);
    } else {
      setDisabledCapture(true);
    }

  }, [videoStream]);


  // Capturar la imagen y convertirla en Base64
  const capturePhoto = async () => {
    const videoElement = document.getElementById("video");
    const base64Image = await captureImageToBase64(videoElement);
    setCapturedImage(base64Image);
  };

  return (
    <div className="event-registration-container">
      <div className="camera-container">
        <video id="video" autoPlay playsInline width="300" height="200" />
        <button onClick={startCamera}>Iniciar Cámara</button>
        <button disabled={disabledCapture} onClick={capturePhoto}>Capturar Imagen</button>
      </div>
      {capturedImage && (
        <div className="captured-image">
          <h3>Imagen Capturada</h3>
          <img src={capturedImage} alt="Imagen Capturada" />
        </div>
      )}
    </div>
  );
};

export default EventRegistration;
